import React from "react"
import styles from "../css/spotlight.module.css"
import StyledHero from "../components/StyleHero"
import AniLink from "gatsby-plugin-transition-link/AniLink"
const Spotlight = ({ title, subTitle, info, children, direction, img , invert = false, styleN = "", id}) => {
  var finalStyleText = invert ? styles.moduleTextInvert : styles.module;
  var finalStyleGif = invert ? styles.moduleGifInvert : styles.moduleGif;
  var styleNN = styleN == "" ? styles.moduloGifImg : styles.moduloImg;
  var tagA = styleN == "" ? "" : <AniLink fade to="/autoql" className={styles.tagA}>Lear more about AutoQL --></AniLink>;
  // let finalCCS = direction.includes("right") ? styles.complementRight : styles.complementRightEmpty
  return (
    <StyledHero home="true" id={id} >
      <div className={styles.banner}>
        <div className={finalStyleText}>
          <h1 className={direction}>{title}</h1>
          <h2 className={direction}>{subTitle}</h2>
          <div dangerouslySetInnerHTML={{ __html: info }} />
          {children}
          {tagA}
        </div>
        <div className={finalStyleGif}>
          <img src={img} className={styleNN} alt=""/>
        </div>
      </div>
    </StyledHero>
  )
}

export default Spotlight
