import React from "react"
import Layout from "../components/Layout"
import SpotlighFinal from "../constans/homeContent"
import BannerFinal from "../constans/bannerHome"
import FootFinal from "../constans/pieHome"
import SEO from "../components/SEO"

const IndexPage = () => {
  
  return (
  <Layout>
    <SEO titlePage="Welcome to Chata" />
    <BannerFinal />
    <SpotlighFinal/>
    <FootFinal />
  </Layout>
)}

export default IndexPage
