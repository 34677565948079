import React from "react"
import {useStaticQuery, graphql} from 'gatsby'
import StyledHero from "../components/StyleHero"
import Banner from "../components/Banner"
import ic1 from "../images/ic1.png"
import ic2 from "../images/ic2.png"
import ic3 from "../images/ic3.png"
import ic4 from "../images/ic4.png"
import ic5 from "../images/ic5.png"
const getSpoths = graphql`
query{
  
  homeBanner:allContentfulBanner{
    edges{
      node{
        title
        subTitle
        text
        titleButton
        url
      }
    }
  }
}
`

const FootFinal = () => {
  const response = useStaticQuery(getSpoths)
  const bannerQuery = response.homeBanner.edges[0].node
  
  return (
    <StyledHero home="true" >
     <Banner title="Not our first rodeo..." subTitle="Enabling the dynamic translation of Natural Language to database query language is what we do." info="To date, we've successfully built powerful language models
for databases of some of the most widely-used software applications:" styleContent="" home="true" >
       <div class="homeDiv">
          <img src={ic1} />
          <img src={ic2} />
          <img src={ic3} />
          <img src={ic4} />
          <img src={ic5} />
       </div>
     </Banner>
   </StyledHero>
  )
}
export default FootFinal
// export default test()