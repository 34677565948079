import React from "react"
import stylesService from "../css/service.module.css"
import Spotlight from "../components/Spotlight"
import {useStaticQuery, graphql} from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll';
import { FaArrowRight } from "react-icons/fa"
import {Icon} from 'antd'
const getSpoths = graphql`
query{
  spothHome:allContentfulSpothHome(sort:{fields: order}){
    edges{
      node{
        title
        invertImage
        contentful_id
        cardImage{
            file{
              url
            }
        }
        order
      	description{
          childContentfulRichText {
            html
          }
        }
      }
    }
  }
}
`
const SpotlightFinal = () => {
  const response = useStaticQuery(getSpoths)
  const spothsQuery = response.spothHome.edges
  return (
    <div>
        {spothsQuery.map(({ node },index) => {
          let finalID = `content${node.contentful_id}`
          //let finalText = node.fullDescription.content[0].content[0].value.replace(/\xa0/gi, ' ')
          let finalText = node.description.childContentfulRichText.html
            return (
              <div key={index}>
                <Spotlight title={node.title} id={finalID} subTitle="" info={finalText} invert={node.invertImage} img={node.cardImage.file.url} styleN="No">
                </Spotlight>
              </div>
            )
          })}
    </div>
  )
}
export default SpotlightFinal
// export default test()