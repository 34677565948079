import React from "react"
import {useStaticQuery, graphql} from 'gatsby'
import StyledHero from "../components/StyleHero"
import Banner from "../components/Banner"
const getSpoths = graphql`
query{
  
  homeBanner:allContentfulBanner{
    edges{
      node{
        title
        subTitle
        text
        titleButton
        url
      }
    }
  }
}
`

const BannerFinal = () => {
  const response = useStaticQuery(getSpoths)
  const bannerQuery = response.homeBanner.edges[0].node
  
  return (
    <StyledHero home="true" >
     <Banner title="AutoQL" subTitle="Deliver state-of-the-art conversational data experiences, naturally." info="" styleContent="" home="true" >
       <div class="homeDiv">
          <input type="email" placeholder="Your mail address" class="input-text" />
          <a href="#" className="btn-white" rel="noreferrer noopener">
              {//bannerQuery.titleButton
              }
              Yes, count me in
          </a>
       </div>
     </Banner>
   </StyledHero>
  )
  
  
}
export default BannerFinal
// export default test()